<template>
  <div>
    <vineyards-component />
    <footer-component />
  </div>
</template>

<script>
import VineyardsComponent from "@/components/vineyards/VineyardsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

export default {
  name: "Vineyards",
  title: "Vineyards | Baja California Health Tourism",
  components: { FooterComponent, VineyardsComponent },
};
</script>

<style scoped></style>
